import { CartFragment, OrderDetailFragment, OrderWithCustomerFragment } from '../generated-types';
import { GIFT_CARD_PAYMENT_METHOD_CODE } from '../../checkout/common/constants';

export function getGiftCardDiscount(cart?: CartFragment | OrderDetailFragment): number {
    if (isOrderDetailFragment(cart)) {
        return cart.payments
            .filter((p) => p.method === GIFT_CARD_PAYMENT_METHOD_CODE)
            .reduce((sum, p) => sum + p.amount, 0);
    } else {
        const totalAfterRewardPoints = cart?.totalWithTax - (cart?.customFields?.rewardPointsUsed ?? 0);
        const giftCardBalanceAvailable = cart?.giftCardsApplied.reduce((sum, gc) => sum + gc.balance, 0);
        return Math.min(totalAfterRewardPoints, giftCardBalanceAvailable);
    }
}

export function getTotalPayable(
    cart?: CartFragment | OrderWithCustomerFragment | OrderDetailFragment,
): number {
    if (isOrderDetailFragment(cart)) {
        const giftCardDiscount = getGiftCardDiscount(cart);
        return cart.totalWithTax - cart.customFields.rewardPointsUsed - giftCardDiscount;
    } else {
        const totalAfterRewardPoints = cart?.totalWithTax - (cart?.customFields?.rewardPointsUsed ?? 0);
        const giftCardBalanceAvailable = cart?.giftCardsApplied.reduce((sum, gc) => sum + gc.balance, 0);
        return Math.max(0, totalAfterRewardPoints - giftCardBalanceAvailable);
    }
}

function isOrderDetailFragment(
    input?: CartFragment | OrderWithCustomerFragment | OrderDetailFragment,
): input is OrderDetailFragment {
    return input && !!(input as OrderDetailFragment).payments;
}
