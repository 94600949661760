<vsf-dropdown #dropdown="vsfDropdown">
    <button vsfDropdownTrigger class="text-xs text-gray-600 underline" (click)="fetchCountries()">
        Change shipping destination
    </button>
    <div class="p-4 divide-y divide-gray-200" vsfDropdownContent>
        <kb-country-select
            [ngModel]="selectedCountryCode"
            [availableCountries]="availableCountries$ | async"
            (ngModelChange)="selectCountry($event, dropdown)"
        >
            ></kb-country-select
        >
    </div>
</vsf-dropdown>
