import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { DataService } from '../../../core/providers/data/data.service';
import * as Codegen from '../../../common/gql/graphql';
import {
    GetAvailableCountriesDocument,
    SetShippingAddressDocument,
} from '../../../common/gql/graphql';
import { Observable, Subject, switchMap } from 'rxjs';

import { map } from 'rxjs/operators';
import { DropdownComponent } from '../dropdown/dropdown.component';
import { ActiveService } from '../../../core/providers/active-order/active.service';
import { AsyncPipe } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { CountrySelectComponent } from '../country-select/country-select.component';
import { DropdownContentDirective } from '../dropdown/dropdown-content.directive';
import { DropdownTriggerDirective } from '../dropdown/dropdown-trigger.directive';

@Component({
    selector: 'kb-shipping-country-selector',
    templateUrl: './shipping-country-selector.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        DropdownComponent,
        DropdownTriggerDirective,
        DropdownContentDirective,
        CountrySelectComponent,
        FormsModule,
        AsyncPipe,
    ],
})
export class ShippingCountrySelectorComponent implements OnInit {
    availableCountries$: Observable<Codegen.GetAvailableCountriesQuery['availableCountries']>;
    fetchCountries$ = new Subject<void>();
    @Input() selectedCountryCode: string;
    constructor(private dataService: DataService, private activeService: ActiveService) {}

    ngOnInit() {
        this.availableCountries$ = this.fetchCountries$.pipe(
            switchMap(() => this.dataService.query(GetAvailableCountriesDocument)),
            map((data) => data.availableCountries),
        );
    }

    fetchCountries() {
        this.fetchCountries$.next();
    }

    selectCountry(countryCode: string, dropdown: DropdownComponent) {
        this.dataService
            .mutate(SetShippingAddressDocument, {
                input: {
                    streetLine1: '',
                    countryCode,
                },
            })
            .subscribe(() => {
                this.activeService.refreshDefaultShippingLocation();
                dropdown.close();
            });
    }
}
