<section aria-labelledby="summary-heading" class="mt-auto" *ngIf="cart?.lines.length">
    <div
        *ngIf="cart?.discounts.length"
        class="border border-dotted border-sienna py-3 px-6 mb-4 sm:px-8 sm:py-4 sm:rounded-lg text-sm"
    >
        <h2 class="text-gray-500">Discounts applied to this order:</h2>
        <ng-container *ngFor="let discount of cart?.discounts">
            <div class="flex items-center justify-between">
                <dt class="text-sienna">
                    {{ discount.description }}
                </dt>
                <dd class="font-medium text-sienna">
                    {{ discount.amountWithTax | formatPrice }}
                </dd>
            </div>
        </ng-container>
    </div>

    <div class="bg-gray-50 border p-6 sm:p-8 sm:rounded-lg">
        <h2 id="summary-heading" class="sr-only">Order summary</h2>

        <div class="flow-root">
            <dl class="-my-4 text-sm">
                <div class="pt-4 flex items-center justify-between">
                    <dt class="text-gray-600">Subtotal</dt>
                    <dd class="font-medium text-gray-900">
                        {{ linesSubtotal | formatPrice }}
                    </dd>
                </div>
                <div class="pb-4 flex items-center justify-between">
                    <dt class="text-gray-400">Included VAT</dt>
                    <dd class="font-medium text-gray-400">
                        {{ cart?.subTotalWithTax - cart?.subTotal | formatPrice }}
                    </dd>
                </div>
                <ng-container>
                    <div class="pt-4 flex items-center justify-between">
                        <dt class="text-gray-600">
                            Shipping to {{ cart?.shippingAddress?.country }}
                            <div class="text-xs text-gray-600"></div>
                            <kb-shipping-country-selector
                                *ngIf="canSetShipping"
                                [selectedCountryCode]="cart?.shippingAddress?.countryCode"
                            ></kb-shipping-country-selector>
                        </dt>
                        <dd class="font-medium text-gray-900"></dd>
                    </div>

                    <ng-container *ngFor="let shippingLine of cart?.shippingLines">
                        <div class="pb-4 flex items-center justify-between">
                            <dt class="text-xs text-gray-600">{{ shippingLine.shippingMethod.name }}</dt>
                            <dd class="font-medium text-gray-900">
                                {{ shippingLine.priceWithTax | formatPrice }}
                            </dd>
                        </div></ng-container
                    >
                </ng-container>

                <div class="py-4 flex items-center justify-between">
                    <dt class="font-medium text-gray-900">Order total</dt>
                    <dd class="font-medium text-gray-900">
                        {{ cart?.totalWithTax | formatPrice }}
                    </dd>
                </div>
                <div
                    *ngIf="cart?.customFields.rewardPointsUsed"
                    class="py-4 flex items-center justify-between"
                >
                    <dt class="text-green-700">Reward points used:</dt>
                    <dd class="font-medium text-green-700">
                        {{ -cart.customFields.rewardPointsUsed | formatPrice }}
                    </dd>
                </div>
                <div *ngIf="cart?.giftCardsApplied?.length" class="py-4 flex items-center justify-between">
                    <dt class="text-green-700">
                        Gift Voucher:
                        <span
                            class="mx-2 px-1 border rounded"
                            *ngFor="let giftCard of cart.giftCardsApplied"
                            >{{ giftCard.code }}</span
                        >
                    </dt>
                    <dd class="font-medium text-green-700">
                        {{ -giftCardDiscount | formatPrice }}
                    </dd>
                </div>
                <div class="py-4 flex items-center justify-between">
                    <dt class="text-base font-medium text-gray-900">Total payable</dt>
                    <dd class="text-base font-medium text-gray-900">
                        {{ totalPayable | formatPrice }}
                    </dd>
                </div>
            </dl>
        </div>
    </div>
</section>
