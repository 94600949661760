import { Inject, Injectable, Optional, PLATFORM_ID } from '@angular/core';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';

export interface HeaderScript {
    src?: string;
    sourceCode?: string;
    async?: boolean;
}

@Injectable({
    providedIn: 'root',
})
export class HeaderScriptsService {
    constructor(
        @Inject(PLATFORM_ID) private platformId: any,
        @Optional() @Inject(DOCUMENT) private document?: Document,
    ) {}

    addScripts(scripts: HeaderScript[]): void {
        scripts.forEach((script) => this.addScript(script));
    }

    addScript(script: HeaderScript): Promise<void> {
        return new Promise((resolve) => {
            if (isPlatformBrowser(this.platformId) && this.document) {
                if (script.src) {
                    const scriptTagWithSrc = this.document.createElement('script');
                    scriptTagWithSrc.type = 'text/javascript';
                    scriptTagWithSrc.src = script.src;
                    if (script.async) {
                        scriptTagWithSrc.async = true;
                    }
                    this.document.head.appendChild(scriptTagWithSrc);
                    scriptTagWithSrc.addEventListener('load', () => resolve());
                } else if (script.sourceCode) {
                    const scriptWithSourceCode = this.document.createElement('script');
                    scriptWithSourceCode.type = 'text/javascript';
                    scriptWithSourceCode.text = script.sourceCode;
                    this.document.head.appendChild(scriptWithSourceCode);
                    resolve();
                }
            }
        });
    }
}
