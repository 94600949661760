<div>
    <div class="relative rounded-md bg-white shadow-sm border border-gray-300">
        <div class="absolute inset-y-0 left-0 pl-3 w-12 flex items-center pointer-events-none">
            <img *ngIf="countryCode"
                 [src]="'assets/flags/' + (countryCode | lowercase) + '.svg'"
                class='w-6 h-6 rounded'
            />
        </div>
        <div class="inset-y-0 right-0 pl-12 flex items-center">
            <label for="currency" class="sr-only">Currency</label>
            <select
                id="countryCode"
                name="countryCode"
                [(ngModel)]="countryCode"
                (ngModelChange)='onCountrySelect()'
                class="custom-select focus:ring-primary-500 focus:border-primary-500 h-full w-full py-2 pl-2 pr-7 border-transparent bg-transparent text-gray-500 sm:text-sm rounded-md"
            >
                <option [ngValue]="'GB'">United Kingdom</option>
                <option disabled>──────────</option>
                <option *ngFor="let country of availableCountries" [ngValue]="country.code">
                    {{ country.name }}
                </option>
            </select>
        </div>
    </div>
</div>

