import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, FormsModule } from '@angular/forms';

import { CountryFragment } from '../../../common/gql/graphql';
import { NgIf, NgFor, LowerCasePipe } from '@angular/common';

@Component({
    selector: 'kb-country-select',
    templateUrl: './country-select.component.html',
    styleUrls: ['./country-select.component.scss'],
    changeDetection: ChangeDetectionStrategy.Default,
    providers: [{ provide: NG_VALUE_ACCESSOR, multi: true, useExisting: CountrySelectComponent }],
    standalone: true,
    imports: [
        NgIf,
        FormsModule,
        NgFor,
        LowerCasePipe,
    ],
})
export class CountrySelectComponent implements ControlValueAccessor {
    @Input() availableCountries: CountryFragment[];
    countryCode = '';
    private onChangeFn: (val: string) => void;
    private onTouchFn: () => void;

    registerOnChange(fn: any): void {
        this.onChangeFn = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouchFn = fn;
    }

    writeValue(obj: any): void {
        this.countryCode = obj;
    }

    onCountrySelect() {
        this.onChangeFn(this.countryCode);
    }
}
