import { CartFragment } from '../generated-types';

export interface MinimumOrderAmountItem {
    product: CartFragment['lines'][number]['productVariant']['product'];
    minimum: number;
    total: number;
}

export function buildMinimumOrderAmountMap(cart?: CartFragment) {
    const minimumOrderAmountMap = new Map<string, MinimumOrderAmountItem>();
    cart?.lines.forEach((line) => {
        const product = line.productVariant.product;
        if (product && product.customFields?.minimumOrderQuantity) {
            const existing = minimumOrderAmountMap.get(product.id);
            if (existing) {
                existing.total += line.quantity;
            } else {
                minimumOrderAmountMap.set(product.id, {
                    product,
                    minimum: product.customFields.minimumOrderQuantity,
                    total: line.quantity,
                });
            }
        }
    });
    return minimumOrderAmountMap;
}
