import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { NgIf, NgFor } from '@angular/common';
import { CartFragment } from '../../../common/gql/graphql';
import { getGiftCardDiscount, getTotalPayable } from '../../../common/utils/order-totals-utils';
import { FormatPricePipe } from '../../pipes/format-price.pipe';
import { ShippingCountrySelectorComponent } from '../shipping-country-selector/shipping-country-selector.component';

@Component({
    selector: 'kb-cart-totals',
    templateUrl: './cart-totals.component.html',
    styleUrls: ['./cart-totals.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        NgIf,
        NgFor,
        ShippingCountrySelectorComponent,
        FormatPricePipe,
    ],
})
export class CartTotalsComponent {
    @Input() cart: CartFragment;
    @Input() canSetShipping = false;

    get linesSubtotal(): number {
        return this.cart?.lines.reduce((sum, line) => sum + line.discountedLinePriceWithTax, 0) ?? 0;
    }

    get giftCardDiscount(): number {
        return getGiftCardDiscount(this.cart);
    }

    get totalPayable(): number {
        return getTotalPayable(this.cart);
    }
}
